<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <div class="d-flex pa-3">
            <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon>
            </v-btn>
            <v-btn v-bind="bind.new" v-on="on.new">
              {{ labels.new }}
            </v-btn>
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-btn v-bind="bind.actions" v-on="on.edit(item)">
            <v-icon>{{ icons.edit }}</v-icon>
          </v-btn>
          <v-btn
            v-bind="bind.actions"
            v-on="on.delete(item)"
            color="error"
            :disabled="item.eliminado"
          >
            <v-icon>{{ icons.delete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <Modal v-model="dialog" v-on="on.form" v-bind="bind.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>

<script>
import Modal from "@/components/Modal/ModalEstadosSolicitud.vue";
import { mapGetters, mapActions } from "vuex";

import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "EstadoSolicitudPage",
  mixins: [PageMixin],
  data() {
    return {
      headers: [
        {
          text: "Estado",
          align: "start",
          filterable: true,
          value: "estado_descripcion",
        },
        { text: "Codigo", value: "estado" },
        { text: "Fecha creación", value: "fecha_creacion" },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters("EstadosSolicitud", ["getItems"]),

    items() {
      return this.getItems;
    },
  },
  methods: {
    ...mapActions("EstadosSolicitud", ["GET", "PUT", "POST", "DELETE"]),

    setup() {
      this.loading = true;
      this.GET()
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem(data) {
      this.loadingForm = true;
      let METHOD = this.POST;
      const formData = { ...data };
      if (this.selected) {
        METHOD = this.PUT;
        formData.id = this.selected._id;
      }
      METHOD(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.closeForm();
          this.setup();
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteItem(item) {
      this.DELETE(item._id)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
        })
        .catch((error) => {
          this.snackbar(error.info);
        });
    },
  },
};
</script>
