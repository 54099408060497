<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close"
          ><v-icon>{{ icons.close }}</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-text-field
            label="Código"
            required
            v-model="form.estado"
            :rules="[(v) => !!v || 'El campo código es requerido.']"
          ></v-text-field>

          <v-text-field
            label="Nombre"
            required
            v-model="form.estado_descripcion"
            :rules="[(v) => !!v || 'El campo descripción es requerido.']"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
export default {
  name: "ModalEstadosSolicitudForm",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de Estados de Solicitud",
      form: {
        estado: "",
        estado_descripcion: "",
      },
    };
  },
};
</script>
